import "bootstrap/dist/css/bootstrap.min.css";
import React from "react";

import NavTop from "./components/NavTop";
import NavSide from "./components/NavSide";
import Home from "./components/Home";
import Portfolio from "./components/Portfolio";
import About from "./components/About";
import Contact from "./components/Contact";

function App() {
  return (
    <React.Fragment>
      <NavSide />
      <NavTop />
      {/* I think sizing is ok for now, xl I think can be same as lg, but maybe investigate xxl */}
      <div className="px-0 min-vh-100 col-sm-10 col-lg-9 ms-sm-5">
        <Home />
        <Portfolio />
        <About />
        <Contact />
      </div>
    </React.Fragment>
  );
}

export default App;
