import React, { useState } from "react";

import Container from "react-bootstrap/Container";
import Collapse from "react-bootstrap/Collapse";
import NavDropdown from "react-bootstrap/NavDropdown";
import Nav from "react-bootstrap/Nav";

import rose from "../assets/rose.png";

function NavSide() {
  const [open, setOpen] = useState(false);

  return (
    <Container
      fluid
      className="vh-100 position-fixed flex-row-reverse p-0 d-none d-sm-flex"
      style={{ zIndex: open ? "2" : "0" }}
    >
      <div
        className="pt-5 border-start border-dark"
        onMouseLeave={() => setOpen(!open)}
        style={{ backgroundColor: "white", opacity: ".9" }}
      >
        <Collapse in={open} dimension="width">
          <div>
            <Container style={{ width: "200px" }} className="mt-4 text-center">
              <Nav.Link href="#portfolio">Portfolio</Nav.Link>
              <Nav.Link href="#about">About</Nav.Link>
              <Nav.Link href="#contact">Contact</Nav.Link>
              <NavDropdown.Divider />
              <Nav.Link href="#home">
                {/* eslint-disable-next-line */}
                <img src={rose} width="25%" />
              </Nav.Link>
            </Container>
          </div>
        </Collapse>
      </div>

      {/*  */}
      {!open && (
        <h2
          className="vh-100 align-self-center pt-4 border-start border-dark"
          style={{ backgroundColor: "white", opacity: ".9" }}
          onMouseEnter={() => setOpen(!open)}
        >
          <i className="bi-chevron-compact-left"></i>
        </h2>
      )}

      {open && (
        <h2
          className="vh-100 align-self-center pt-4 border-start"
          style={{ backgroundColor: "white", opacity: ".9" }}

          // left this for reference in case I don't like onMouseLeave above
          // onMouseEnter={()=> setOpen(!open)}
        >
          <i className="bi-chevron-compact-right"></i>
        </h2>
      )}
    </Container>
  );
}

export default NavSide;

// use these to change arrow, change focus

// onEnter - callback before component expands
// onEntering - callback while component expands
// onEntered - callback after component expands

// onExit - before component collapses
// onExiting - while component collapses
// onExited - after component collapses
