import React from "react";

function Contact() {
  return (
    <div className="px-0" style={{ height: "900px" }}>
      <div
        id="contact"
        className="mx-0 bg-gradient"
        style={{ height: "30vh", backgroundColor: "#035399" }}
      ></div>
      <div className="px-2 px-sm-0">
        <h2 id="title"> Contact </h2>
        <div className="px-1">
          <br />
          <p> Ways to get in touch:</p>
          <br />
          <p>brianpatrickbellinger at gmail dot com</p>
          <p>GitHub: bbellify</p>
          <p>~wordel-sitnec</p>
        </div>
      </div>
    </div>
  );
}

export default Contact;
