import React from "react";

function About() {
  return (
    <div className="px-0" style={{ height: "900px" }}>
      <div
        id="about"
        className="mx-0 bg-gradient"
        style={{ height: "30vh", backgroundColor: "#1F7DCF" }}
      ></div>
      <div className="px-2 px-sm-0">
        <h2 id="title">About</h2>

        <p>
          I've done a few different things, like manage a hotel and teach
          philosophy.
        </p>
        <p>Currently I'm a web developer working on building on Urbit.</p>
        <p>Other interests include running, guitar, and btc.</p>
      </div>
    </div>
  );
}

export default About;
