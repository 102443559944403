import React from "react";

function Home() {
  return (
    // figure out responsive margins for the body components
    <div>
      <div
        id="home-m"
        className="d-sm-none bg-gradient"
        style={{ height: "30vh", backgroundColor: "#0C3C66" }}
      ></div>
      <div
        id="home"
        className="d-none d-sm-block bg-gradient"
        style={{ height: "55vh", backgroundColor: "#0C3C66" }}
      ></div>
      <div className="px-2 px-sm-0" style={{ height: "45vh" }}>
        <h1 id="name">Brian Bellinger</h1>
        <p>building tools for ejecting</p>
      </div>
      <div className="d-sm-none" style={{ height: "15vh" }}></div>
    </div>
  );
}

export default Home;
