import React from "react";

function Portfolio() {
  return (
    <div className="px-0" style={{ height: "900px" }}>
      <div
        id="portfolio"
        className="mx-0 bg-gradient pb-5"
        style={{ height: "30vh", backgroundColor: "#1862A3" }}
      ></div>
      <div className="px-2 px-sm-0">
        <h2 id="title"> Portfolio </h2>
        <br></br>
        <h5>Urbit apps</h5>
        <p>
          <a
            className="text-black"
            href="https://www.github.com/bbellify/knox"
            target="_blank"
            rel="noreferrer"
            style={{ position: "relative", zIndex: "2" }}
          >
            %knox - self-hosted password manager Urbit app
          </a>
        </p>
        <p>
          <a
            className="text-black"
            href="https://www.github.com/bbellify/dailys"
            target="_blank"
            rel="noreferrer"
            style={{ position: "relative", zIndex: "2" }}
          >
            %dailys - do your dailys
          </a>
        </p>
        <br />
        <h5>Other stuff</h5>
        <p>
          <a
            className="text-black"
            href="https://www.github.com/bbellify/knox-extension"
            target="_blank"
            rel="noreferrer"
            style={{ position: "relative", zIndex: "2" }}
          >
            %knox companion chromium extension
          </a>
        </p>
      </div>
    </div>
  );
}

export default Portfolio;
