import React, { useState } from "react";

import NavDropdown from "react-bootstrap/NavDropdown";
import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";

import rose from "../assets/rose.png";

function NavTop() {
  const [open, setOpen] = useState(false);

  return (
    <div
      className="d-sm-none sticky-top"
      style={{ backgroundColor: "white", opacity: ".85" }}
    >
      <Navbar expand="">
        <Navbar.Toggle
          onClick={() => setOpen(!open)}
          className="ms-auto mb-1 me-3 p-1"
        />
        <Navbar.Offcanvas
          className="w-50 pt-5"
          scroll="true"
          placement="end"
          style={{ opacity: ".85" }}
        >
          <div className="text-center">
            <Nav.Link href="#portfolio">Portfolio</Nav.Link>
            <Nav.Link href="#about">About</Nav.Link>
            <Nav.Link href="#contact">Contact</Nav.Link>
            <NavDropdown.Divider />
            <Nav.Link href="#home-m">
              {/* eslint-disable-next-line */}
              <img src={rose} width="20%" />
            </Nav.Link>
          </div>
        </Navbar.Offcanvas>
      </Navbar>
    </div>
  );
}

export default NavTop;
